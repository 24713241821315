import {send} from 'ajax';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
urlParams.append('path', window.location.pathname); // for "path" column in "hits" table

export const fromDesktop = urlParams.get('home') === 'yes' ? true : false;

//hitPage();


  // only redirect from homepage
  if (window.location.pathname == '/' || window.location.pathname == '/santa/signup') {
   //ab_redirect(); - turn off ab test
   homeRedirect();
  }


export async function hitPage(){
  try {
    let link_url = "/santa/hit?"+urlParams.toString(); 
    const hit = await send("GET", link_url);
  }
  catch (err) {
    console.log('hit page testing err: ' + err);
  }
}

export async function homeRedirect() {
console.log('m', fromDesktop, urlParams, urlParams.get('home'));
  if (!detectMob() && !fromDesktop) {
    console.log('desktop redirect');
    var ab_queryString = window.location.search;
    var ab_urlParams = new URLSearchParams(ab_queryString);
    window.location.href ='/santa/desktop?' + ab_urlParams; 
  }
  else {
    console.log('mobile view');
  }
}

export async function signup_redirect() {
  var ab_queryString = window.location.search;
  var ab_urlParams = new URLSearchParams(ab_queryString);
  if (detectMob()) {
    window.location.href ='/santa/signup?' + ab_urlParams; 
  }
}

export async function ab_redirect() {
  var ab_queryString = window.location.search;
  var ab_urlParams = new URLSearchParams(ab_queryString);
  const ab_test = await send('GET', '/santa/check_ab_testing?' + ab_urlParams);
  var ab_ = ab_test.result;
  if (ab_ == 'b') {
    window.location.href ='/santa/signup?' + ab_urlParams; 
  }
}

function detectMob() {
    return  ( window.innerWidth <= 900 ) ;
}
