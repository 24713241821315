import Rails from '@rails/ujs';

export function send(method, path, data = null) {
  return new Promise((resolve, reject) => {
    try {
      const request = {
        type: method,
        url: path,
        data: data
      };
      console.log(`sending request: ${JSON.stringify(request)}`);
      request.success = (response) => {
        console.log(`received response to ${method} ${path}: ${JSON.stringify(response)}`);
        resolve(response);
      };
      request.error = (response) => {
        console.log(`received error response to ${method} ${path}: ${JSON.stringify(response)}`);
        reject(response);
      };
      Rails.ajax(request);
    }
    catch (err) {
      console.log(`client-side error for ${method} ${path}: ${err}`);
      reject(err);
    }
  });
}

// For test/debug:
window.clearUser = () => send("POST", "/santa/clear_user").then(() => { console.log('OK'); }).catch(err => { console.log(err); });
window.getUser = async () => { try { const {user_email} = await send("GET", "/santa/get_user"); console.log(`logged in: ${user_email}`); } catch(err) {console.log(err); } };
window.setUser = (user_email) => send("POST", "/santa/set_user", `user_email=${encodeURIComponent(user_email)}`).then(() => { console.log('OK'); }).catch(err => { console.log(err); });
