import {send} from 'ajax';
import {send_fb_purchase} from 'third_party_custom_callbacks';
import {send_ga_test_bought} from 'third_party_custom_callbacks';
import {send_ga_test_purchase} from 'third_party_custom_callbacks';
import {send_ga_ab_test} from 'third_party_custom_callbacks';
import {getCookie} from 'browser';
import {replaceDiv} from 'anime';

const cc_submit_button = document.getElementById('cc_submit_button');
var checkout;
var change_method_button;
var myDeviceData;
set_free_button();

export var user_has_more_then_one_order = false;
export var checkout_user_email = '';

export function runPayment(payload){
  var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');
  if (!payload){
    payload = {nonce:null, deviceData:myDeviceData};
  }
  getBTnonce(payload).then( function() {
   console.log('Callback - was there a delay?');
   if (checkout.result == 'success') {
     send_fb_purchase(checkout.amount, checkout.payment_id, checkout.user_email, checkout.user_phone);

     checkout_user_email = checkout.user_email;
     if (checkout.dup_user == 'true') {
       user_has_more_then_one_order = true; 
       console.log('dup user');
     } 
     else {
       user_has_more_then_one_order = false; 
       console.log('no dup user');
     }  

     if (user_has_more_then_one_order && !is_signup) {
       document.getElementById('ordering_email').value = checkout.user_email;
       document.getElementById('send_confirm_me_form').submit(); 
     }
     else {
       if (is_signup) {
         if (parseFloat(checkout.amount) > 0) {
           let santa_personal = document.getElementById('santa_personal');
           if (santa_personal) {
             santa_personal.innerHTML = 'Would you like to personalize more for ' + (document.getElementById('child_name').value || 'your child') + '? (optional)';
           }
           replaceDiv('step-4','step-5');
         }
         else {
           Turbolinks.visit('/accounts/menu'); // we dont have payment info for upgrades
         }
       }
       else {
         Turbolinks.visit('/accounts/menu'); 
       } 
     }
   }
   else {
     let this_error = document.getElementById('error');
     this_error.innerHTML = checkout.error;
     this_error.classList.remove('hidden');
     if (change_method_button){ 
       change_method_button.classList.remove('hidden');
     }
     if (is_signup) {
      document.getElementById('processing').classList.add('hidden');
      let btToggle = document.getElementsByClassName('braintree-toggle');
      for (let i=0; i<btToggle.length; i++) {
        btToggle[i].classList.remove('hidden');
      }
      let btUpperContainer = document.getElementsByClassName('braintree-methods-initial');
      for (let i=0; i<btUpperContainer.length; i++) {
        btUpperContainer[i].classList.add('hidden');
      }
     } 
     if (document.getElementById("old_braintree")) {
       if (cc_submit_button){
         cc_submit_button.classList.remove('hidden');
       }
      if (is_signup) {
        document.getElementById('error').innerHTML = checkout.error;
        document.getElementById('error').classList.remove('hidden');
      } 
      else {
        Turbolinks.visit('/santa/text_packages?message=' + checkout.error); // Maybe different redirect here
      }
     }
   }
 }, false);
}

async function getBTnonce(payload){
  console.log('payload: ' + payload);
  console.log(payload);
  var fbp = getCookie('_fbp');
  var fbc = getCookie('_fbc');
  var website = window.location.href; 
  var captcha = grecaptcha.getResponse();
  var ab_test_result = document.getElementById('ab_test_value').innerHTML;

  console.log('captcha passing as: ', captcha);
  checkout = await send("POST", "/santa/package_checkout", 'nonce=' + payload.nonce + '&device_data=' + payload.deviceData + '&package_id=' + document.getElementById('c_selected_package').innerHTML + '&promocode=' + document.getElementById('submitted_promocode').innerHTML + '&order_id=' + document.getElementById('order_id').innerHTML + '&fbp=' + fbp + '&fbc=' + fbc + '&website=' + website + '&g-recaptcha-response=' + captcha + '&ttp=' + getCookie('_ttp') + '&ab_test_value=' + ab_test_result);
}

export function set_free_button(){
  var sfb = document.getElementById('submit-free_button');
  if (sfb) {
    document.getElementById('submit-free_button').addEventListener('click', (event) => {runPayment(null)});
   }
}

