// Opera 8.0+
const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]" 
const isSafari = navigator.vendor.includes('Apple') ||  /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;


export function is_safari(){
  return isSafari;
}

export function print_browsers(){
var output = 'Detecting browsers by ducktyping: ';
output += 'isFirefox: ' + isFirefox + ' ';
output += 'isChrome: ' + isChrome + ' ';
output += 'isSafari: ' + isSafari + ' ';
output += 'isOpera: ' + isOpera + ' ';
output += 'isIE: ' + isIE + ' ';
output += 'isEdge: ' + isEdge + ' ';
output += 'isEdgeChromium: ' + isEdgeChromium + ' ';
output += 'isBlink: ' + isBlink + ' ';
console.log(output);
}

export function getCookie(cname) {
  console.log('looking for cookie ' + cname);
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
