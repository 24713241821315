import {send} from 'ajax';
import {countDown} from 'countdown';
import {createDropIn} from 'bt_drop_in';
import {isUK} from 'international';
import {getPrice} from 'international';
import {paymentSymbol} from 'international';
import {is_desktop} from 'desktop';

const promocode_area = document.getElementById('new_santas_promocode');
const promocode_checkbox = document.getElementById('giftcode');
const promocode_apply = document.getElementById('apply_promo_button');
const promocode_clear = document.getElementById('clear_promo_link');
const submitted_promocode = document.getElementById('submitted_promocode');
const discount_amount = document.getElementById('discount_amount');
const package_cost = document.getElementById('package_cost');
const fc_p = document.getElementById('fc_p');
const chosen_plan = document.getElementById('chosen_plan');
const chosen_plan_price = document.getElementById('chosen_plan_price');
const err_message = document.getElementById('err_message');

const package_queryString = window.location.search;

var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');


export function recalcPromo(){
  var new_discount_amount = (parseFloat(package_cost.innerHTML) * (parseFloat(discount_amount.innerHTML)/100)).toFixed(2);
  var new_final_cost = (parseFloat(package_cost.innerHTML) - new_discount_amount).toFixed(2);
  document.getElementById('promo_calc').innerHTML = paymentSymbol() + parseFloat(package_cost.innerHTML).toFixed(2) + ' - ' + paymentSymbol() + parseFloat(new_discount_amount).toFixed(2) + ' = ' + paymentSymbol() + parseFloat(new_final_cost).toFixed(2); 
  document.getElementById('promo_total').innerHTML = 'Total: ' + paymentSymbol() + parseFloat(new_final_cost).toFixed(2);
  var promo_name_string = submitted_promocode.innerHTML;
  document.getElementById('gift_desc').innerHTML = "Gift Code Promotion '" + promo_name_string + "'";
  console.log('new final cost: ' + new_final_cost);
  document.getElementById('fc').innerHTML = new_final_cost 
  fc_p.innerHTML = new_final_cost;
  if (new_final_cost < 1) {
    if (document.getElementById('c_selected_package').innerHTML != document.getElementById('package_one_id').innerHTML) {
      // free only applies to single package
      document.getElementById('err_message').innerHTML = 'This promotion code is only valid for the single package';
      clearPromo();
    }
    else {
      console.log('FREE!');
      document.getElementById('purchase_form_info').classList = 'hidden';
      document.getElementById('submit-free_button').classList = 'mobile_checkout_button';
      document.getElementById('submit-payment_button').classList = 'hidden';
      document.getElementById('bt_dropin').classList = 'hidden';
    }
  }
  else {
    console.log('not free!');
    document.getElementById('purchase_form_info').classList = '';
    document.getElementById('submit-free_button').classList = 'hidden';
    document.getElementById('submit-payment_button').classList = '';
    document.getElementById('bt_dropin').classList = '';
  }
  //loadPaypal();
}

export function clearPromo(addRemove = true){
  document.getElementById('promo_packages').classList = 'promo_packages hidden';
  document.getElementById('promo_prompt').classList.remove('hidden');
  if (document.getElementById('title_header_mobile_line6')) {
    //document.getElementById('title_header_mobile_line6').classList.remove('promo');
  }
  promocode_area.className = 'hidden';
  promocode_checkbox.checked = false;
  document.getElementById("promocode_name").value = null;
  submitted_promocode.innerHTML = 'None';
  // if free
  document.getElementById('purchase_form_info').classList = '';
  document.getElementById('submit-free_button').classList = 'hidden';
  document.getElementById('submit-payment_button').classList = '';
  document.getElementById('bt_dropin').classList = '';
  if (is_signup) {
     document.getElementById('anime-wrapper').classList.remove('promo');
     if (addRemove) {
       document.getElementById('anime-wrapper').classList.add('promo-remove');
     }
     else {
       document.getElementById('anime-wrapper').classList.remove('promo-remove');
     }
     if (document.getElementById('purchase_package_div')) {
        document.getElementById('purchase_package_div').classList.remove('promo');
     }
  }
}

export async function applyPromo() {
  if (err_message) {
    err_message.innerHTML = '';
  }
  console.log('applyPromo');
  window.location.hash ='#step-4';
  var promocode = document.getElementById('promocode_name').value.trim();
  var p_type = document.getElementById('c_selected_package').innerHTML;
  if (promocode == '') {
    if (err_message) {
      err_message.innerHTML = 'Please enter a valid promotion code'; 
      clearPromo(false);
    }
  } 
  else {
    try {
      const data = await send("POST", "/santa/get_promo_amount", "promocode=" + promocode + "&package_id=" + p_type);
      console.log(data);
      if (data.err_message == 'none'){
        document.getElementById('promo_packages').classList = 'promo_packages hidden';
        submitted_promocode.innerHTML = data.promo_name;
        discount_amount.innerHTML = data.promo_percent_off;
        promocode_area.className = 'hidden';
        document.getElementById('promo_packages').classList.remove('hidden');
        document.getElementById('promo_prompt').classList.add('hidden');
        if (is_signup) {
          document.getElementById('anime-wrapper').classList.add('promo');
          document.getElementById('anime-wrapper').classList.remove('promo-remove');
          if (document.getElementById('purchase_package_div')) {
            document.getElementById('purchase_package_div').classList.add('promo');
          }
        }
        recalcPromo();
      } 
      else {  // error message here
        if (err_message) {
          err_message.innerHTML = data.err_message;
          if (document.getElementById('anime-wrapper')) {
            document.getElementById('anime-wrapper').classList.add('promo-remove');
          }
        }
      }
    }
    catch (err) {
      console.log('Get Promo error: ' + err);
    }
  }
}

export function togglePromo() {
  if (promocode_checkbox.checked == true) {
    promocode_area.className = '';
  }
  else {
    promocode_area.className = 'hidden';
  }
}
