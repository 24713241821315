var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');

import {fromDesktop} from "hit";

  adjust_for_desktop();

if (!detectMob()) {
  window.addEventListener('resize', adjust_for_desktop, true);
}

function adjust_for_desktop() {


  // ORDER PAGE 
  if (document.getElementById('elf_version_1')) {
    if (is_desktop()) {
       if(document.getElementById('mobile')) {
         document.getElementById('mobile').id = 'desktop';
       }
    }
    else {
       if(document.getElementById('desktop')) {
         document.getElementById('desktop').id = 'mobile';
       }
    }
  }
  // END ORDER PAGE 

  // HOMEPAGE
  if (document.getElementById('homepage')) {
    if (is_desktop()) {
      document.getElementById('static_sign_up_div').classList = 'static_sign_up_div hidden';
      document.getElementById('desktop').classList = '';
      document.getElementById('mobile').classList = 'hidden';
    }
    else {
      if (document.getElementById('static_sign_up_div') && document.getElementById('desktop') && document.getElementById('mobile')) {
        document.getElementById('static_sign_up_div').classList = 'static_sign_up_div';
        document.getElementById('desktop').classList = 'hidden';
        document.getElementById('mobile').classList = '';
      }
    }
  }

  // END OF HOMEPAGE

  // FEATURES PAGE
  if (document.getElementById('features_page')) {
    if (is_desktop()) {
      document.getElementById('desktop_features').classList = '';
      document.getElementById('mobile_features').classList = 'hidden';
    }
    else {
      document.getElementById('desktop_features').classList = 'hidden';
      document.getElementById('mobile_features').classList = '';
    }
  }
  // END OF FEATURES PAGE

  // ABOUT PAGE
  if (document.getElementById('notes_page')) {
    if (is_desktop()) {
      document.getElementById('desktop_notes').classList = '';
      document.getElementById('mobile_notes').classList = 'hidden';
    }
    else {
      document.getElementById('desktop_notes').classList = 'hidden';
      document.getElementById('mobile_notes').classList = '';
    }
  }
  // END OF ABOUT PAGE

  // FAQS PAGE
  if (document.getElementById('desktop_faqs')) {
    if (is_desktop()) {
      document.getElementById('desktop_faqs').classList = '';
      document.getElementById('mobile_faqs').classList = 'hidden';
    }
    else {
      document.getElementById('desktop_faqs').classList = 'hidden';
      document.getElementById('mobile_faqs').classList = '';
    }
  }
  // END OF FAQS PAGE

  // VIDEO PAGE
  if (document.getElementById('video_page')) {
    if (is_desktop()) {
      document.getElementById('desktop_video').classList = '';
      document.getElementById('mobile_video').classList = 'hidden';
    }
    else {
      document.getElementById('desktop_video').classList = 'hidden';
      document.getElementById('mobile_video').classList = '';
    }
  }
  // END OF VIDEO PAGE

  // Footer    
    if (is_desktop()) {
      if(document.getElementById('desktop_footer')) {
        document.getElementById('desktop_footer').classList = '';
      }
      if(document.getElementById('mobile_footer')) {
        document.getElementById('mobile_footer').classList = 'hidden';
      }
    }
    else {
      if(document.getElementById('desktop_footer')) {
        document.getElementById('desktop_footer').classList = 'hidden';
      }
      if(document.getElementById('mobile_footer')) {
        document.getElementById('mobile_footer').classList = '';
      }
    }
  // END Footer
}

export function is_desktop() {
  if (document.getElementById('whole_page') && !fromDesktop) {
    let body_size = document.getElementById('whole_page').offsetWidth;
    if (body_size > 1080) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}

function detectMob() {
    //console.log('returning detectMob:', window.innerWidth <= 900, window.innerWidth);
    return  ( window.innerWidth <= 900 ) ;
}
