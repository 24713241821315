export function ShowTime() {
  var now = new Date();
  var newdate = new Date();

  var hrs = 18-now.getHours();
  if (hrs <= 0) {
    newdate.setDate(now.getDate() + 1); 
  }
  else {
    newdate.setDate(now.getDate()); 
  }
    newdate.setHours(18);
    newdate.setMinutes('00');
    newdate.setSeconds('00');
  return newdate;
}

export function countDown(){
  // Set the date we're counting down to
  var this_year = new Date().getFullYear();
  var this_month = new Date().getMonth() + 1;
  var countDownDate = new Date("Dec 1, " + this_year + " 00:00:00").getTime();
  // Update the count down every 1 second
  var x = setInterval(function() {
 
    //Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
 
    var day_display = document.getElementById('days');
    var hour_display = document.getElementById('hours');
    var min_display = document.getElementById('mins');
    var sec_display = document.getElementById('secs');
    var full_display = document.getElementById('countdown');
    
    /*  turn off until closer to start
    if (day_display) {
      day_display.innerHTML = days;
      hour_display.innerHTML = hours;
      min_display.innerHTML = minutes;
      sec_display.innerHTML = ("0" + seconds).slice(-2);
    }
    if (full_display) {
      full_display.innerHTML = 'Texts Start in ' + days + ' days ' + hours + ' hours ' + minutes + ':' + ("0" + seconds).slice(-2);
    }
    */
     
  }, 1000);
}
