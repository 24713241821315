import {send} from 'ajax';
import {runPayment} from 'run_payment';
import {print_browsers} from 'browser';
import {is_safari} from 'browser';
const braintree = require('braintree-web');
const dropin = require('braintree-web-drop-in');

export async function createDropIn(){
  try{
    console.log('run createDropIn');
    var ab_queryString = window.location.search;
    var ab_urlParams = new URLSearchParams(ab_queryString);
    var ab_ = 'a';
    var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');
    print_browsers();
    console.log('is_safari is: ' + is_safari());
    if (document.getElementById('dropin-container')){
      document.getElementById('dropin-container').innerHTML = '';
    }

//    if (is_safari()) {
//      const ab_test = await send('GET', '/santa/check_ab_testing?' + ab_urlParams);
//      ab_ = ab_test.result;
//      send("GET", "/santa/store_ab?ab_value=" + ab_test.result_string); // want to run this async
//    }
    const token = await send("GET", "/santa/get_bt_token");
    if (!token) {
      Turbolinks.visit('/santa/text'); // Maybe different redirect here
    }

    // braintree payment types ab test
    //const ab_test = await send('GET', '/santa/return_json_ab_test?' + ab_urlParams);
    //ab_ = ab_test.result;
    ab_ = 'a';
    // Use this bt_params if you want to remove apple pay
    var bt_params_a = 
      {authorization: token.bt_token,
      container: '#dropin-container',
        paypal: {flow: 'vault',
          buttonStyle: {
            size: 'small',
            tagline: 'false'
          }, 
        },
        dataCollector: {
           kount: true // Required if Kount fraud data collection is enabled
        } };
    var bt_params_b = 
      {authorization: token.bt_token,
      container: '#dropin-container'
        ,
        dataCollector: {
           kount: true // Required if Kount fraud data collection is enabled
        } };
    if (ab_ == 'b') {
//    if (document.getElementById('dropin-container').innerHTML){
//      document.getElementById('dropin-container').innerHTML='';
//    }
      var bt_params = bt_params_b;
    }
    else {
      var bt_params = bt_params_a;
    }
    if (is_signup && false) {  // braintree changed apple pay integration - have to update
     // Use this bt_params if you want to add apple pay
     bt_params = 
      {authorization: token.bt_token,
      container: '#dropin-container',
      applePay: {
        displayName: 'TextsFromSanta',
        flow: 'vault',
        paymentRequest: {
          total: {
            label: 'TextsFromSanta',
            amount: document.getElementById('fc_p').innerHTML 
          },
        requiredBillingContactFields: ["postalAddress"]
        }},
        paypal: {
          buttonStyle: {
            size: 'large'
          }, 
          flow: 'vault'
        },
        dataCollector: {
           kount: true // Required if Kount fraud data collection is enabled
        } }
    }  
    var bt_button = document.getElementById('submit-payment_button');
    var terms = document.getElementById('terms');
    dropin.create( bt_params,
     function (createErr, instance) {
      if (createErr) {
        console.error(createErr);
        if (bt_button){
          bt_button.classList = '';
          if (terms) {
            terms.classList = 'terms first';
          }
        }
        return;
      }
      console.log('instance:');
      console.log(instance);
      var cmb = document.querySelector('[data-braintree-id="toggle"]');
      var cont = document.querySelector('[data-braintree-id="sheet-container"]');
      cont.prepend(cmb);
      var change_method_buttons = document.getElementsByClassName('braintree-large-button');
      if (change_method_buttons) {
        var change_method_button = change_method_buttons[0];
        if (change_method_button){
          change_method_button.addEventListener('click', function() { 
            bt_button.classList = '';
            if (terms) {
              terms.classList = 'terms first';
            }
          });
        } 
      }

      bt_button.addEventListener('click',function(){
       bt_button.classList = 'hidden';
       if (change_method_button){ 
         change_method_button.classList.add('hidden');
       }
    
       if (terms) {
         terms.classList = 'terms first no_payment_button';
       }
       instance.requestPaymentMethod( (requestPaymentMethodErr, payload) => {
         if (requestPaymentMethodErr) {
           console.error(requestPaymentMethodErr);
           if (is_signup) {
             document.getElementById('processing').classList.add('hidden');
           } 
           if (bt_button){
             bt_button.classList = '';
             if (terms) {
               terms.classList = 'terms first';
             }
           }
           return;
         }
         runPayment(payload);
        });
      });

      /* add event listener for new form */
      if (is_signup) {
        const collection = document.getElementsByClassName("braintree-option");
        for (let i = 0; i < collection.length; i++) {
          console.log('adding bt listener');
          collection[i].addEventListener('click', function() {
            console.log('clicked');
            setTimeout(function() { window.location.hash ='#step-3';}, 100);
            setTimeout(function() { window.location.hash ='#step-3';}, 50);
          });
        }
      }


    });
  }
  catch (err) {
    console.log('Braintree error: ' + err);
    if (is_signup) {
      document.getElementById('processing').classList.add('hidden');
    } 
    if (bt_button){
      bt_button.classList = '';
      if (terms) {
        terms.classList = 'terms first';
      }
    }
  }
}

